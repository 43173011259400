import { BuilderContent as BuilderContentVariation } from "@builder.io/sdk";
import classNames from "classnames/bind";
import Link from "next/link";
import React, { FC, useMemo, useState } from "react";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useIsArcade } from "@/hooks/useIsArcade";

import { UI_CONTEXTS } from "../../analytics/constants";
import { useCurrentLocale } from "../../context/LanguageContext";
import { ChunkedNav, GenericNavItem } from "../../interfaces/navigation-interface";
import { KINDS, SIZES } from "../buttons/buttons.constants";
import LinkButton from "../buttons/link-button";
import { ApLogo } from "../icons/ap-logo";
import { MobileNavigation } from "../mobile-navigation/mobile-navigation";
import SearchBar from "../search-bar/search-bar";
import { ArcadeNavigation } from "./arcade/navigation";
import { Auth } from "./auth/auth";
import styles from "./navigation.module.scss";
import {
    TopLevelNavigation,
    useAllCategoriesNav,
} from "./top-level-navigation/top-level-navigation";

const cx = classNames.bind(styles);

export interface Props {
    builderNavLinks?: Array<BuilderContentVariation>;
    navigationItems: ChunkedNav;
    mobileFooterNavigationItems?: Array<Array<GenericNavItem>>;
}

export const Navigation: React.FC<Props> = (props: Props) => {
    const { navigationItems } = props;
    const { apHeader, mobileNavigation } = useHeader(props);
    const locale = useCurrentLocale();
    const isNewNav = locale === "en-US";
    const isArcade = useIsArcade();
    const navContainerClassName = cx({
        [styles.navContainer]: true,
    });

    return isArcade ? (
        <ArcadeNavigation {...props} />
    ) : (
        <div data-testid="global-header" className={styles.navWrapper}>
            <div className={isNewNav ? styles.navNoBorder : styles.navBorder}>
                <div className={navContainerClassName}>{apHeader}</div>
                {!isNewNav ? <TopLevelNavigation navigationItems={navigationItems} /> : null}
                {mobileNavigation}
            </div>
        </div>
    );
};

const useHeader = (props: Props) => {
    const { builderNavLinks, navigationItems } = props;
    const [mobileMenuIcon, mobileNavigation, isMobileMenuActive] = useMobileMenuNavigation(props);

    const navElements: [React.ReactNode, React.ReactNode] = useMemo(
        () => [
            <div className={styles.searchInputContainer}>
                <SearchBar />
            </div>,
            <Auth />,
        ],
        []
    );

    // The auth element needs to be sorted differently on mobile in order to
    // render correctly. On desktop, the search box is "before" the auth
    // element. On mobile, it comes after. Here, we are just putting those two
    // elements in the order they need to be in on desktop.
    //
    // In the case of mobile, we swap them.
    const apHeader = useMemo(
        () => (
            <>
                {mobileMenuIcon}
                <div className={styles.logoContainer}>
                    <AppLogo />
                    <NavigationLinks
                        builderNavLinks={builderNavLinks}
                        navigationItems={navigationItems}
                    />
                </div>
                {navElements[0]}
                {navElements[1]}
            </>
        ),
        [builderNavLinks, mobileMenuIcon, navElements, navigationItems]
    );

    return {
        apHeader,
        mobileMenuIcon,
        mobileNavigation,
        isMobileMenuActive,
    };
};

export const AppLogo: FC = () => {
    return (
        <Link prefetch={false} href="/" className={styles.logo}>
            <ApLogo />
        </Link>
    );
};

const NavigationLinks: FC<Props> = ({ builderNavLinks, navigationItems }) => {
    const { allCategoryNav, isNewNav } = useAllCategoriesNav(navigationItems);
    const { allCategoriesWording } = useFeatureFlags();
    return (
        <div className={styles.navigationLinksContainer}>
            {builderNavLinks &&
                builderNavLinks.map(({ data }, index) => {
                    if (isNewNav && data.link.text === allCategoriesWording) {
                        return allCategoryNav;
                    }

                    if (data?.link?.url && data?.link?.text) {
                        return (
                            <div key={index}>
                                <LinkButton
                                    kind={KINDS.UNSET}
                                    size={SIZES.SMALL}
                                    label={data.link.text}
                                    url={data.link.url}
                                    newTab={data.link.newTab}
                                    overrideFocusBorder={false}
                                    uiContext={UI_CONTEXTS.TOP_NAVIGATION}
                                    passHref={data.link.passHref}
                                    forwardUtm={data.link.forwardUtm}
                                />
                            </div>
                        );
                    }
                })}
        </div>
    );
};

const useMobileMenuNavigation = (props: Props) => {
    const { navigationItems, mobileFooterNavigationItems } = props;
    const locale = useCurrentLocale();
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
    const { validAllCategryNav, isNewNav } = useAllCategoriesNav(navigationItems);
    const mobileMenuIconClassName = cx({
        [styles.mobileMenuIcon]: true,
        [styles.mobileMenuIconActive]: isMobileMenuActive,
    });

    const mobileMenuIcon = (
        <div className={styles.mobileNavigationContainer}>
            <div
                onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
                className={mobileMenuIconClassName}
            />
        </div>
    );

    const mobileNavigation = (
        <MobileNavigation
            navigationItems={isNewNav ? validAllCategryNav : navigationItems}
            isMobileMenuActive={isMobileMenuActive}
            footerNavigationItems={mobileFooterNavigationItems}
            locale={locale}
            onToggleMenuActive={setIsMobileMenuActive}
        />
    );

    return [mobileMenuIcon, mobileNavigation, isMobileMenuActive];
};
