import React from "react";

import { useIsArcade } from "@/hooks/useIsArcade";

import styles from "./search-bar.module.scss";

interface SuggestionHeadingProps {
    text: string;
}
export const SuggestionHeading: React.FC<SuggestionHeadingProps> = ({ text }) => {
    const isArcade = useIsArcade();
    return isArcade ? (
        <div className={styles.searchBarSuggestionHeadingArcade}>{text}</div>
    ) : (
        <h2 className={styles.searchBarSuggestionHeading}>{text}</h2>
    );
};
