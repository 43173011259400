import React from "react";

import {
    PORTAL_UI_US_HOST,
    PORTAL_UI_AU_HOST,
    PORTAL_UI_GB_HOST,
    PORTAL_BRANCH_SIGN_UP_CA_URL,
    PORTAL_BRANCH_SIGN_UP_NZ_URL,
    PORTAL_BRANCH_SIGN_UP_GB_URL,
    PORTAL_BRANCH_SIGN_UP_AU_URL,
    PORTAL_BRANCH_SIGN_UP_US_URL,
} from "@/shared/constants";

import { Locale } from "@/types/locale";

import styles from "../navigation.module.scss";
import { menuLinkHREF } from "./auth-authenticated";
import { dropdownItems } from "./auth-authenticated-dropdown-items";
import { AuthUnauthenticated } from "./auth-unauthenticated";

export { menuLinkHREF, dropdownItems };

export const getPortalURL = (locale: Locale): string => {
    switch (locale) {
        case "en-GB":
            return `${PORTAL_UI_GB_HOST}/uk/${locale}`;
        case "it-IT":
            return `${PORTAL_UI_GB_HOST}/it`;
        case "fr-FR":
            return `${PORTAL_UI_GB_HOST}/fr`;
        case "es-ES":
            return `${PORTAL_UI_GB_HOST}/es`;
        case "en-NZ":
        case "en-AU":
            return `${PORTAL_UI_AU_HOST}/${locale}`;
        case "en-US":
        default:
            return `${PORTAL_UI_US_HOST}/${locale}`;
    }
};

export const getBranchPortalURL = (locale: Locale): string => {
    switch (locale) {
        case "it-IT":
            return `${PORTAL_UI_GB_HOST}/it`;
        case "fr-FR":
            return `${PORTAL_UI_GB_HOST}/fr`;
        case "es-ES":
            return `${PORTAL_UI_GB_HOST}/es`;
        case "en-CA":
        case "fr-CA":
            return PORTAL_BRANCH_SIGN_UP_CA_URL;
        case "en-NZ":
            return PORTAL_BRANCH_SIGN_UP_NZ_URL;
        case "en-GB":
            return PORTAL_BRANCH_SIGN_UP_GB_URL;
        case "en-AU":
            return PORTAL_BRANCH_SIGN_UP_AU_URL;
        case "en-US":
            return PORTAL_BRANCH_SIGN_UP_US_URL;
        default:
            return `${PORTAL_UI_US_HOST}/${locale}`;
    }
};

export const Auth: React.FC = () => {
    return (
        <div className={styles.authDropdownMenuButtonContainer}>
            <AuthUnauthenticated />
        </div>
    );
};
