import { Icon } from "@afterpaytouch/core";
import { BuilderContent as BuilderContentVariation } from "@builder.io/sdk";
import className from "classnames/bind";
import React from "react";

import { UI_CONTEXTS } from "@/analytics/constants";

import { KINDS, SIZES } from "@/components/buttons/buttons.constants";
import LinkButton from "@/components/buttons/link-button";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";

import { ChunkedNav, GenericNavItem } from "@/interfaces/navigation-interface";

import { Locale } from "@/types/locale";

import { AppLogo } from "../navigation";
import { MobileNavigationItem } from "./mobile-navigation-item";
import styles from "./mobileNavigation.module.scss";

const cx = className.bind(styles);

export interface Props {
    builderNavLinks: Array<BuilderContentVariation>;
    navigationItems: ChunkedNav;
    isMobileMenuActive: boolean;
    footerNavigationItems: Array<Array<GenericNavItem>>;
    locale: Locale;
    onToggleMenuActive: (isActive: boolean) => void;
}

export const ArcadeMobileNavigation: React.FC<Props> = (props: Props) => {
    const { builderNavLinks, navigationItems, isMobileMenuActive, onToggleMenuActive } = props;
    const { allCategoriesWording } = useFeatureFlags();
    const mobileNavigationContainerClassName = cx({
        [styles.mobileNavigationContainerArcade]: true,
        [styles.mobileNavigationContainerArcadeActive]: isMobileMenuActive,
    });

    const mobileNavigationContentClassName = cx({
        [styles.mobileNavigationContentArcade]: true,
    });

    const onToggleMenu = () => {
        onToggleMenuActive(!isMobileMenuActive);
    };

    return (
        <div className={mobileNavigationContainerClassName}>
            <div className={styles.mobileNavigationBarArcade}>
                <div className={styles.mobileNavigationBarLogoArcade}>
                    <AppLogo />
                </div>
                <div onClick={onToggleMenu} className={styles.mobileNavigationCloseIconArcade}>
                    {isMobileMenuActive && <Icon.Close size="Small" />}
                </div>
            </div>
            <div className={mobileNavigationContentClassName}>
                <ul>
                    {builderNavLinks.map(({ data }, index) => {
                        if (data.link.text === allCategoriesWording) {
                            return navigationItems.map(([navItem, subNavItems], index) => (
                                <MobileNavigationItem
                                    key={`${navItem.id}-${index}`}
                                    text={navItem.displayText ?? navItem.text}
                                    url={navItem.link}
                                    categoryId={navItem.categoryId}
                                    subMenuItemChunks={subNavItems}
                                />
                            ));
                        }

                        return (
                            <li key={index} className={styles.mobileNavigationItem}>
                                <LinkButton
                                    kind={KINDS.UNSET}
                                    size={SIZES.UNSET}
                                    overrideFocusBorder={false}
                                    url={data.link.url}
                                    label={data.link.text}
                                    uiContext={UI_CONTEXTS.TOP_NAVIGATION}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
