import { Modal, Button, Heading } from "@afterpaytouch/core";
import classNames from "classnames/bind";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { NEW_USER_SEEN_WELCOME_MODAL_STORE_KEY } from "@/shared/constants";

import { getLocalStorageKey, setLocalStorageKey } from "@/utils/localStorage";

import { UI_CONTEXTS } from "@/analytics/constants";
import EVENTS from "@/analytics/events";
import { trackUserEvent } from "@/analytics/tracking";

import {
    useAuthUserData,
    useAuthUserHasOrders,
    useAuthUserSpendLimit,
} from "@/context/AuthContext";
import { useCurrentLocale } from "@/context/LanguageContext";

import { getLogo } from "@/components/icons/ap-logo";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useIsArcade } from "@/hooks/useIsArcade";

import styles from "./new-user-modal.module.scss";

const cx = classNames.bind(styles);
export interface Props {
    forceClose: boolean;
    onCloseCallback?: () => void;
}
export const NewUserModal: React.FC<Props> = ({ forceClose = false, onCloseCallback }) => {
    const { t } = useTranslation();
    const router = useRouter();
    const isArcade = useIsArcade();

    const { siteNextWelcomeModal } = useFeatureFlags();
    const isWelcomeModalEnabled = !!siteNextWelcomeModal?.enabled;

    const userData = useAuthUserData();
    const userSpendLimit = useAuthUserSpendLimit();
    const userHasOrders = useAuthUserHasOrders();

    const locale = useCurrentLocale();
    const title = t("welcomeModal.title");
    const subTitle = t("welcomeModal.subTitle");
    const detailTitle = t("welcomeModal.detailTitle");
    const detail = t("welcomeModal.detail");
    const youKnowTitle = t("welcomeModal.youKnowTitle");
    const youKnowDetail = t("welcomeModal.youKnowDetail");
    const startShopping = t("welcomeModal.startShopping");

    const Logo = useMemo(() => getLogo(locale, "Large", isArcade), [locale, isArcade]);

    const newUserSeenWelcomeModalStoreKey = `${NEW_USER_SEEN_WELCOME_MODAL_STORE_KEY}-${userData.uuid}`;
    const [hasUserAlreadySeenModal, setHasUserAlreadySeenModal] = useState<boolean>(
        !!getLocalStorageKey(newUserSeenWelcomeModalStoreKey)
    );

    const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);

    useEffect(() => {
        setShouldShowModal(
            !!userSpendLimit &&
                !!isWelcomeModalEnabled &&
                !hasUserAlreadySeenModal &&
                !forceClose &&
                !userHasOrders
        );
    }, [userSpendLimit, isWelcomeModalEnabled, hasUserAlreadySeenModal, forceClose, userHasOrders]);

    const sendAnalytics = useCallback(
        (eventName: string) => {
            trackUserEvent(locale, router.asPath, UI_CONTEXTS.TOP_NAVIGATION, eventName, {
                userSpendLimit,
            });
        },
        [locale, router?.asPath, userSpendLimit]
    );

    const modalShowCallback = useCallback(() => {
        setLocalStorageKey(newUserSeenWelcomeModalStoreKey, true);
        sendAnalytics(EVENTS.WEB_LOGGED_IN_USER_SHOWN_WELCOME_MODAL);
    }, [newUserSeenWelcomeModalStoreKey, sendAnalytics]);

    const modalCloseCallback = useCallback(() => {
        setShouldShowModal(false);
        onCloseCallback?.();
        setHasUserAlreadySeenModal(true);
    }, [onCloseCallback]);
    return (
        <Modal
            show={shouldShowModal}
            onShow={modalShowCallback}
            onClose={modalCloseCallback}
            onBackdropClick={modalCloseCallback}
            backgroundColor={"Bondi Mint"}
        >
            <Modal.Header>
                <div className={styles.logo}>{Logo}</div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.newUserModal}>
                    <div className={styles.section}>
                        <Heading size="XXXL">{title}</Heading>
                    </div>

                    <div className={cx([styles.subTitleSection, styles.section])}>
                        <Heading size="XL">{subTitle}</Heading>
                        <div className={styles.subTitleSpendLimit}>
                            <Heading size="XXL">{userSpendLimit}</Heading>
                        </div>
                    </div>

                    <div className={styles.section}>
                        <Heading size="M">{detailTitle}</Heading>
                        <div>{detail}</div>
                    </div>

                    <div className={styles.section}>
                        <Heading size="M">{youKnowTitle}</Heading>
                        <div>{youKnowDetail}</div>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Footer>
                <Button.Secondary onClick={modalCloseCallback} padding="Fluid">
                    {startShopping}
                </Button.Secondary>
            </Modal.Footer>
        </Modal>
    );
};
