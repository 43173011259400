import classnames from "classnames/bind";
import React, { KeyboardEventHandler, MouseEventHandler } from "react";

import { useCurrentLocale } from "@/context/LanguageContext";

import { UI_CONTEXTS } from "../../../../analytics/constants";
import EVENTS from "../../../../analytics/events";
import { KINDS, SIZES } from "../../../buttons/buttons.constants";
import LinkButton from "../../../buttons/link-button";
import styles from "../../navigation.module.scss";

const cx = classnames.bind(styles);

const ALL_CATEGORY_ID = "/";
const ALL_CATEGORY_EN = "All categories";
const ALL_CATEGORY_FR = "Toutes les catégories";

export interface Props {
    active: boolean;
    text: string;
    bold: boolean;
    categoryId: string;
    link: string;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
    onKeyDown?: KeyboardEventHandler;
}

export const TopLevelNavigationItem: React.FC<Props> = (props: Props) => {
    const locale = useCurrentLocale();
    const { active, text, bold, link, categoryId, onMouseEnter, onMouseLeave, onKeyDown } = props;

    const topLevelNavigationItemClassName = cx({
        [styles.topLevelNavigationItem]: true,
        [styles.topLevelNavigationItemActive]: active,
    });

    const isLinkDisabled =
        ALL_CATEGORY_ID === categoryId && (locale === "en-CA" || locale === "fr-CA");

    return (
        <li
            className={topLevelNavigationItemClassName}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onKeyDown={onKeyDown}
        >
            <LinkButton
                disabled={false}
                fluid={false}
                newTab={false}
                kind={KINDS.UNSET}
                size={SIZES.SMALL}
                url={isLinkDisabled ? "" : link}
                label={text}
                bold={[ALL_CATEGORY_EN, ALL_CATEGORY_FR].includes(text) || bold}
                overrideFocusBorder={false}
                analytics={{
                    uiContext: UI_CONTEXTS.TOP_NAVIGATION,
                    eventName: EVENTS.SHOP_DIRECTORY_CATEGORY_LIST_CLICK,
                    eventProps: {
                        categoryId,
                        categoryName: text,
                    },
                }}
            />
        </li>
    );
};
