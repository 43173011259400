import { BuilderContent } from "@builder.io/sdk";
import classnames from "classnames";
import classNames from "classnames/bind";
import React, { ReactNode, useMemo } from "react";

import { locales } from "@/translations/config";

import { Footer } from "@/components/footer/footer";
import { MessageBanners } from "@/components/message-banners/message-banners";
import { Navigation } from "@/components/navigation/navigation";

import { useIsArcade } from "@/hooks/useIsArcade";
import { useNextQueryParams } from "@/hooks/useNextQueryParams";

import { ChunkedNav, GenericNavItem } from "@/interfaces/navigation-interface";

import { Locale } from "@/types/locale";
import { PageMeta } from "@/types/page";

import { useClientRedirections } from "../hooks/useClientRedirections";
import styles from "./layout.module.scss";
import SharedHead from "./shared-head";

const cx = classNames.bind(styles);

type OwnProps = {
    children: ReactNode;
    currentLocale: Locale;
    headerNavigationItems: ChunkedNav;
    footerLocalNavItems: ChunkedNav;
    footerRegionalNavItems: Array<Array<GenericNavItem>>;
    builderMessages?: Array<BuilderContent>;
    builderNavLinks?: Array<BuilderContent>;
    useOldStyles?: boolean;
    preventIndexing?: boolean;
    showSmartBanner?: boolean;
};

type Props = OwnProps & PageMeta;

const DefaultLayout: React.FC<Props> = ({
    children,
    currentLocale,
    headerNavigationItems,
    footerLocalNavItems,
    footerRegionalNavItems,
    builderMessages,
    builderNavLinks,
    title,
    description,
    useOldStyles = false,
    preventIndexing = false,
    showSmartBanner = true,
}) => {
    useClientRedirections();
    const queryParams = useNextQueryParams();
    const isArcade = useIsArcade();
    const isSkipLogin = queryParams.skip_login === "true"; // @TODO Remove @lilly

    const hideNav = useMemo(
        () => queryParams.hide_navigation === "true" || isSkipLogin,
        [queryParams.hide_navigation, isSkipLogin]
    );
    const hideFooter = useMemo(
        () => queryParams.hide_footer === "true" || isSkipLogin,
        [queryParams.hide_footer, isSkipLogin]
    );

    const mainClassName = cx({
        [styles.hideNavigationMain]: true,
        [styles.hideSmartBanner]: !showSmartBanner,
        [styles.main]: !hideNav && !isArcade,
        [styles.mainNewNav]: !hideNav && isArcade,
    });

    const arcadeMigrationWrapperClassName = cx({
        [styles.arcade]: isArcade,
        [styles.defaultLayout]: true,
    });

    return (
        <>
            <SharedHead
                currentLocale={currentLocale}
                description={description}
                title={title}
                useOldStyles={useOldStyles}
                showSmartBanner={showSmartBanner}
                preventIndexing={preventIndexing}
            />
            <div className={classnames(arcadeMigrationWrapperClassName, isArcade ? "arcade" : "")}>
                {builderMessages && <MessageBanners builderMessages={builderMessages} />}
                {!hideNav && (
                    <Navigation
                        navigationItems={headerNavigationItems}
                        builderNavLinks={builderNavLinks}
                        mobileFooterNavigationItems={footerRegionalNavItems}
                    />
                )}
                <main className={mainClassName}>{children}</main>
                {!hideFooter && (
                    <Footer
                        locales={locales}
                        currentLocale={currentLocale}
                        footerLocalNavItems={footerLocalNavItems}
                        footerRegionalNavItems={footerRegionalNavItems}
                    />
                )}
            </div>
        </>
    );
};

export default DefaultLayout;
