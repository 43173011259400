import classnames from "classnames/bind";
import React from "react";

import { Props as LocaleSwitcherProps } from "@/components/LocaleSwitcher";
import Grid from "@/components/layout/grid";

import { useIsArcade } from "@/hooks/useIsArcade";

import { ChunkedNav, GenericNavItem } from "@/interfaces/navigation-interface";

import { FooterLocal } from "./footer-local";
import { FooterRegional } from "./footer-regional";
import styles from "./footer.module.scss";

const cx = classnames.bind(styles);

type Props = {
    footerLocalNavItems: ChunkedNav;
    footerRegionalNavItems: Array<Array<GenericNavItem>>;
} & LocaleSwitcherProps;
export const Footer: React.FC<Props> = ({
    locales,
    currentLocale,
    footerLocalNavItems,
    footerRegionalNavItems,
}) => {
    const isArcade = useIsArcade();
    const footerClassName = cx({
        [styles.footer]: true,
        [styles.footerArcade]: isArcade,
    });
    return (
        <div data-testid="global-footer" className={footerClassName}>
            <Grid fullWidth fullHeight autoFill>
                <FooterLocal
                    locales={locales}
                    currentLocale={currentLocale}
                    navigationItems={footerLocalNavItems}
                />
            </Grid>
            <Grid fullWidth fullHeight autoFill>
                <FooterRegional
                    navigationItems={footerRegionalNavItems}
                    currentLocale={currentLocale}
                />
            </Grid>
        </div>
    );
};
