import className from "classnames/bind";
import { useTranslation } from "next-i18next";
import React, { useMemo } from "react";

import { UI_CONTEXTS } from "@/analytics/constants";
import EVENTS from "@/analytics/events";

import {
    KINDS as BUTTON_KINDS,
    SIZES as BUTTON_SIZES,
} from "@/components/buttons/buttons.constants";
import LinkButton from "@/components/buttons/link-button";
import { ApLogo } from "@/components/icons/ap-logo";

import { GenericNavItem } from "@/interfaces/navigation-interface";

import { Locale } from "../../../types/locale";
import styles from "./footer.module.scss";
import { Language } from "./language";

const cx = className.bind(styles);

type Props = {
    navigationItems: Array<Array<GenericNavItem>>;
    currentLocale: Locale;
};
export const ArcadeFooterRegional: React.FC<Props> = ({ navigationItems, currentLocale }) => {
    const { t } = useTranslation();
    const legalTextClassName = cx({
        [styles.legalText]: true,
    });
    const logoClassName = cx({
        [styles.logo]: true,
    });

    const links = navigationItems.map((chunk: Array<GenericNavItem>, index: number) => (
        <ul className={styles.colSmall} key={index}>
            {chunk?.map((chunkItem: GenericNavItem) => {
                return (
                    <li key={chunkItem.id}>
                        <LinkButton
                            url={chunkItem.link}
                            label={chunkItem.text}
                            kind={BUTTON_KINDS.UNSET}
                            size={BUTTON_SIZES.UNSET}
                            passHref={true}
                            analytics={{
                                eventProps: {
                                    footerLink: chunkItem.link,
                                    footerLinkText: chunkItem.text,
                                },
                                eventName: EVENTS.FOOTER_LINK_CLICK,
                                uiContext: UI_CONTEXTS.FOOTER_NAVIGATION,
                            }}
                        />
                    </li>
                );
            })}
        </ul>
    ));

    const legalTextContent1 = t("footer.legalText.line1"),
        legalTextContent2 = t("footer.legalText.line2");
    const legalText = useMemo(
        () =>
            legalTextContent1 && (
                <div className={legalTextClassName}>
                    <p dangerouslySetInnerHTML={{ __html: legalTextContent1 }}></p>
                    <br />
                    <p dangerouslySetInnerHTML={{ __html: legalTextContent2 }}></p>
                </div>
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [legalTextContent1, legalTextContent2]
    );

    const acknowledgementText = useMemo(() => {
        if (currentLocale !== "en-AU") {
            return null;
        }
        return (
            <div className={styles.acknowledgementText}>
                <p>{t("footer.acknowledgementText.line1")}</p>
                <br />
                <p>
                    {t("footer.acknowledgementText.line2")}
                    <b>{t("footer.acknowledgementText.line3")}</b>
                </p>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocale]);

    return (
        <>
            {links}
            <div className={styles.languageLabel}>
                <Language />
            </div>
            <div className={logoClassName}>
                <ApLogo />
            </div>
            {legalText}
            {acknowledgementText}
            <div className={styles.copyright}>
                <p className={styles.copyrightText}>© 2025 Afterpay</p>
            </div>
        </>
    );
};
