import { Icon } from "@afterpaytouch/core";
import { BuilderContent as BuilderContentVariation } from "@builder.io/sdk";
import classNames from "classnames/bind";
import Link from "next/link";
import React, { FC, useCallback, useMemo, useState } from "react";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import useViewport from "@/hooks/useViewport";

import { UI_CONTEXTS } from "../../../analytics/constants";
import { useCurrentLocale } from "../../../context/LanguageContext";
import { ChunkedNav, GenericNavItem } from "../../../interfaces/navigation-interface";
import { KINDS, SIZES } from "../../buttons/buttons.constants";
import LinkButton from "../../buttons/link-button";
import { ApLogo } from "../../icons/ap-logo";
import SearchBar from "../../search-bar/search-bar";
import { Auth } from "../auth/auth";
import { useAllCategoriesNav } from "../top-level-navigation/top-level-navigation";
import { ArcadeMobileNavigation } from "./mobile-navigation/mobile-navigation";
import styles from "./navigation.module.scss";

const cx = classNames.bind(styles);

export interface Props {
    builderNavLinks?: Array<BuilderContentVariation>;
    navigationItems: ChunkedNav;
    mobileFooterNavigationItems?: Array<Array<GenericNavItem>>;
}

export const ArcadeNavigation: React.FC<Props> = (props: Props) => {
    const { arcadeHeader, mobileNavigation, isInputEnabled, isMobileMenuActive } = useHeader(props);
    const navWrapperClassName = cx({
        [styles.navWrapper]: true,
    });
    const navContainerClassName = cx({
        [styles.navContainer]: true,
        [styles.navContainerArcadeSmall]: isInputEnabled,
        [styles.navContainerArcadeMenuActive]: isMobileMenuActive,
    });

    return (
        <div data-testid="global-header" className={navWrapperClassName}>
            <div className={styles.navArcadeBorder}>
                <div className={navContainerClassName}>{arcadeHeader}</div>
                {mobileNavigation}
            </div>
        </div>
    );
};

const useHeader = (props: Props) => {
    const { builderNavLinks, navigationItems } = props;
    const [isInputEnabled, setIsInputEnabled] = useState(false);
    const [mobileMenuIcon, mobileNavigation, isMobileMenuActive] = useMobileMenuNavigation(props);
    const { isMobile } = useViewport();

    const enableSearchBar = useCallback(() => {
        setIsInputEnabled(true);
    }, []);

    const disableSearchBar = useCallback(() => {
        setIsInputEnabled(false);
    }, []);

    const navElements: [React.ReactNode, React.ReactNode, React.ReactNode] = useMemo(
        () => [
            <div className={styles.searchInputContainer}>
                <div onClick={enableSearchBar}>
                    <Icon.Search size="Small" />
                </div>
            </div>,
            <div className={styles.searchInputContainer}>
                <SearchBar />
            </div>,
            <Auth />,
        ],
        [enableSearchBar]
    );

    const arcadeMobileHeader = useMemo(
        () =>
            isMobileMenuActive ? (
                <>
                    <div className={styles.logoContainer}>
                        <AppLogo />
                    </div>
                    {mobileMenuIcon}
                </>
            ) : isInputEnabled ? (
                <>
                    <SearchBar size="XSmall" />
                    <div className={styles.searchInputCloseIcon} onClick={disableSearchBar}>
                        <Icon.Close size="Small" />
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.logoContainer}>
                        <AppLogo />
                        <NavigationLinks
                            builderNavLinks={builderNavLinks}
                            navigationItems={navigationItems}
                        />
                    </div>
                    {navElements[0]}
                    {navElements[2]}
                    {mobileMenuIcon}
                </>
            ),
        [
            builderNavLinks,
            disableSearchBar,
            isInputEnabled,
            isMobileMenuActive,
            mobileMenuIcon,
            navElements,
            navigationItems,
        ]
    );

    const arcadeDesktopHeader = useMemo(
        () => (
            <>
                <div className={styles.logoContainer}>
                    <AppLogo />
                    <NavigationLinks
                        builderNavLinks={builderNavLinks}
                        navigationItems={navigationItems}
                    />
                </div>
                {navElements[1]}
                {navElements[2]}
            </>
        ),
        [builderNavLinks, navElements, navigationItems]
    );

    const arcadeHeader = isMobile ? arcadeMobileHeader : arcadeDesktopHeader;

    return {
        arcadeHeader,
        mobileMenuIcon,
        mobileNavigation,
        isInputEnabled,
        isMobileMenuActive,
    };
};

export const AppLogo: FC = () => {
    return (
        <Link prefetch={false} href="/" className={styles.logo}>
            <ApLogo />
        </Link>
    );
};

const NavigationLinks: FC<Props> = ({ builderNavLinks, navigationItems }) => {
    const { allCategoryNav } = useAllCategoriesNav(navigationItems);
    const { allCategoriesWording } = useFeatureFlags();
    return (
        <div className={styles.navigationLinksContainer}>
            {builderNavLinks &&
                builderNavLinks.map(({ data }, index) => {
                    if (data.link.text === allCategoriesWording) {
                        return allCategoryNav;
                    }

                    if (data?.link?.url && data?.link?.text) {
                        return (
                            <div key={index}>
                                <LinkButton
                                    kind={KINDS.UNSET}
                                    size={SIZES.SMALL}
                                    label={data.link.text}
                                    url={data.link.url}
                                    newTab={data.link.newTab}
                                    overrideFocusBorder={false}
                                    uiContext={UI_CONTEXTS.TOP_NAVIGATION}
                                    passHref={data.link.passHref}
                                    forwardUtm={data.link.forwardUtm}
                                />
                            </div>
                        );
                    }
                })}
        </div>
    );
};

const useMobileMenuNavigation = (props: Props) => {
    const { navigationItems, mobileFooterNavigationItems, builderNavLinks } = props;
    const locale = useCurrentLocale();
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
    const { validAllCategryNav } = useAllCategoriesNav(navigationItems);
    const mobileMenuIconClassName = cx({
        [styles.mobileMenuIcon]: true,
        [styles.mobileMenuIconArcade]: true,
        [styles.mobileMenuIconArcadeActive]: isMobileMenuActive,
    });

    const mobileMenuIcon = (
        <div className={styles.mobileNavigationContainer}>
            <div
                onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
                className={mobileMenuIconClassName}
            >
                {isMobileMenuActive && <Icon.Close size="Small" />}
            </div>
        </div>
    );

    const mobileNavigation = (
        <ArcadeMobileNavigation
            builderNavLinks={builderNavLinks}
            navigationItems={validAllCategryNav}
            isMobileMenuActive={isMobileMenuActive}
            footerNavigationItems={mobileFooterNavigationItems}
            locale={locale}
            onToggleMenuActive={setIsMobileMenuActive}
        />
    );

    return [mobileMenuIcon, mobileNavigation, isMobileMenuActive];
};
