import { localeCountryNameMap } from "@/translations/config";

import { useCurrentLocale } from "@/context/LanguageContext";

import styles from "./language.module.scss";

export const Language: React.FC = () => {
    const locale = useCurrentLocale();
    const label = localeCountryNameMap[locale];
    return (
        <div className={styles.container}>
            <div className={styles.globeIcon} />
            <div className={styles.label}>{label}</div>
        </div>
    );
};
