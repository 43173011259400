import className from "classnames/bind";
import React from "react";

import { ChunkedNav, GenericNavItem } from "../../interfaces/navigation-interface";
import { Locale } from "../../types/locale";
import { MobileNavigationItem } from "./mobile-navigation-item/mobile-navigation-item";
import styles from "./mobileNavigation.module.scss";

const cx = className.bind(styles);

export interface Props {
    navigationItems: ChunkedNav;
    isMobileMenuActive: boolean;
    footerNavigationItems: Array<Array<GenericNavItem>>;
    locale: Locale;
    onToggleMenuActive: (isActive: boolean) => void;
}

export const MobileNavigation: React.FC<Props> = (props: Props) => {
    const { navigationItems, isMobileMenuActive } = props;

    const mobileNavigationContainerClassName = cx({
        [styles.mobileNavigationContainer]: true,
        [styles.mobileNavigationContainerActive]: isMobileMenuActive,
    });

    const mobileNavigationContentClassName = cx({
        [styles.mobileNavigationContent]: true,
    });

    return (
        <div className={mobileNavigationContainerClassName}>
            <div className={mobileNavigationContentClassName}>
                <ul>
                    {navigationItems.map(([navItem, subNavItems], index) => (
                        <MobileNavigationItem
                            key={`${navItem.id}-${index}`}
                            text={navItem.text}
                            url={navItem.link}
                            categoryId={navItem.categoryId}
                            subMenuItemChunks={subNavItems}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};
